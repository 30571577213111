<template>
  <div class="bg-black text-white min-h-screen py-8">
    <div class="container mx-auto px-4 max-w-6xl">
      <!-- 标题 -->
      <div class="mb-8">
        <h1 class="text-4xl font-bold text-red-600">3即梦AI 图像生成</h1>
        <p class="text-gray-400 mt-2">一次生成4张独特的图片供您选择</p>
      </div>

      <!-- 内容区域 -->
      <div class="bg-gray-900 rounded-lg shadow-lg p-6">
        <!-- 提示词输入 -->
        <div class="mb-6">
          <label class="block text-sm font-medium mb-2">上传参考图片</label>
          <div class="flex items-center space-x-4">
            <div class="relative">
              <input
                type="file"
                ref="fileInput"
                @change="handleFileUpload"
                accept="image/*"
                class="hidden"
              />
              <button
                @click="$refs.fileInput.click()"
                class="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition duration-300 flex items-center space-x-2"
                :disabled="isLoading"
              >
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span>选择图片</span>
              </button>
            </div>
            <div v-if="uploadedImageUrl" class="relative w-20 h-20">
              <img
                :src="uploadedImageUrl"
                class="w-full h-full object-cover rounded-md"
                alt="已上传图片"
              />
              <button
                @click="removeUploadedImage"
                class="absolute -top-2 -right-2 bg-red-500 rounded-full p-1 hover:bg-red-600"
              >
                <svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <!-- 提示词输入 -->
        <div class="mb-6">
          <label for="prompt" class="block text-sm font-medium mb-2">图像提示词</label>
          <textarea
              id="prompt"
              v-model="prompt"
              rows="3"
              placeholder="详细描述您想要生成的图像内容，越详细效果越好"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500 bg-gray-800 text-white resize-none"
              :disabled="isLoading"
          ></textarea>
        </div>

        <!-- 提示词输入后，添加尺寸选择 -->
        <div class="mb-6">
          <label for="imageSize" class="block text-sm font-medium mb-2">选择图片尺寸</label>
          <select
              id="imageSize"
              v-model="selectedSize"
              class="w-full p-3 border border-gray-700 rounded-md focus:ring-red-500 focus:border-red-500 bg-gray-800 text-white"
              :disabled="isLoading"
          >
            <option v-for="(size, index) in imageSizes"
                    :key="index"
                    :value="size.value">
              {{ size.label }}
            </option>
          </select>
        </div>

        <!-- 生成按钮 -->
        <button
            @click="generateImages"
            class="w-full bg-red-600 text-white px-4 py-3 rounded-md font-semibold hover:bg-red-700 transition duration-300 flex items-center justify-center"
            :disabled="isLoading"
        >
          <span v-if="!isLoading">生成4张图片</span>
          <div v-else class="flex items-center space-x-2">
            <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>AI绘画中...</span>
          </div>
        </button>
      </div>

      <!-- 图片结果展示 -->
      <div v-if="generatedImages && generatedImages.length" class="mt-8">
        <h2 class="text-xl font-semibold mb-4">生成结果</h2>
        <div class="grid grid-cols-2 gap-6">
          <div v-for="(image, index) in generatedImages"
               :key="index"
               class="relative group bg-gray-900 rounded-lg overflow-hidden">
            <!-- 图片容器 -->
            <div class="aspect-square relative">
              <img
                  :src="image.url"
                  class="w-full h-full object-cover"
                  :alt="`生成图片 ${index + 1}`"
                  loading="lazy"
              />
              <!-- 悬浮操作层 -->
              <div class="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center space-x-4">
                <!-- 预览按钮 -->
                <button
                    @click="previewImage(image.url)"
                    class="bg-white text-gray-900 px-4 py-2 rounded-full hover:bg-gray-100 transition-colors duration-200 flex items-center space-x-2"
                >
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                  </svg>
                  <span>预览</span>
                </button>
                <!-- 下载按钮 -->
                <button
                    @click="downloadImage(image.url)"
                    class="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition-colors duration-200 flex items-center space-x-2"
                >
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                  </svg>
                  <span>下载</span>
                </button>
              </div>
            </div>
            <!-- 图片序号 -->
            <div class="absolute top-2 left-2 bg-black bg-opacity-50 px-2 py-1 rounded text-sm">
              图片 #{{index + 1}}
            </div>
          </div>
        </div>
      </div>

      <!-- 预览模态框 -->
      <div v-if="previewUrl"
           class="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
           @click="previewUrl = null">
        <img
            :src="previewUrl"
            class="max-w-full max-h-[90vh] object-contain"
            alt="预览图片"
        />
        <button
            class="absolute top-4 right-4 text-white hover:text-gray-300"
            @click="previewUrl = null"
        >
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>

      <!-- 错误信息 -->
      <div v-if="errorMessage"
           class="fixed bottom-4 right-4 bg-red-500 text-white px-6 py-3 rounded-lg shadow-lg">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'

export default {
  setup() {
    const generatedImages = ref([])
    const prompt = ref('')
    const isLoading = ref(false)
    const errorMessage = ref('')
    const previewUrl = ref(null)
    const selectedSize = ref('1024x1024')
    const imageSizes = [
      { label: '标准方形 (1024x1024)', value: '1024x1024' },
      { label: '横版 (1920x1080)', value: '1920x1080' },
      { label: '竖版 (1080x1920)', value: '1080x1920' },
      { label: '宽幅横版 (1024x480)', value: '1024x480' }
    ]
    const taskId = ref(null)
    const pollingInterval = ref(null)
    const uploadedImageUrl = ref(null)
    const fileInput = ref(null)

    const generateImages = async () => {
      console.log('开始生成图片，提示词:', prompt.value, '尺寸:', selectedSize.value)
      
      if (!prompt.value.trim()) {
        console.log('提示词为空，终止请求')
        errorMessage.value = '请输入提示词'
        setTimeout(() => errorMessage.value = '', 3000)
        return
      }

      isLoading.value = true
      errorMessage.value = ''
      generatedImages.value = []
      console.log('清空旧的生成结果，当前generatedImages:', generatedImages.value)

      try {
        console.log('发送创建任务请求...')
        const response = await axios.post('/proxy/api/jimeng/v1/images/generations', {
          model: "seededit",
          prompt: prompt.value,
          size: selectedSize.value
        })
        
        console.log('创建任务响应:', response.data)

        if (response.data.code === 0 && response.data.task_id) {
          taskId.value = response.data.task_id
          console.log('获取到任务ID:', taskId.value, '开始轮询任务状态')
          startPolling()
        } else {
          throw new Error('创建任务失败')
        }
      } catch (error) {
        console.error('创建任务失败:', error)
        console.error('错误详情:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        })
        errorMessage.value = error.message || '创建图片任务失败'
        setTimeout(() => errorMessage.value = '', 3000)
        isLoading.value = false
      }
    }

    const startPolling = () => {
      console.log('启动轮询，间隔: 3000ms')
      pollingInterval.value = setInterval(async () => {
        try {
          console.log('发送轮询请求，taskId:', taskId.value)
          const response = await axios.get(`/proxy/api/jimeng/v1/images/generations/${taskId.value}`)
          console.log('轮询响应:', response.data)
          
          switch (response.data.status) {
            case 'SUCCESS':
              console.log('任务成功完成，获取到图片数据:', response.data.data)
              generatedImages.value = response.data.data
              console.log('更新后的generatedImages:', generatedImages.value)
              stopPolling()
              isLoading.value = false
              break
              
            case 'FAILED':
              console.log('任务失败')
              errorMessage.value = '生成图片失败'
              setTimeout(() => errorMessage.value = '', 3000)
              stopPolling()
              isLoading.value = false
              break
              
            case 'UNKNOWN':
              console.log('任务处理中，继续轮询')
              break
              
            default:
              console.warn('未知的任务状态:', response.data.status)
              errorMessage.value = '未知的任务状态'
              setTimeout(() => errorMessage.value = '', 3000)
              stopPolling()
              isLoading.value = false
          }
        } catch (error) {
          console.error('轮询失败:', error)
          console.error('轮询错误详情:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status
          })
          errorMessage.value = error.message || '检查任务状态失败'
          setTimeout(() => errorMessage.value = '', 3000)
          stopPolling()
          isLoading.value = false
        }
      }, 3000)
    }

    const stopPolling = () => {
      console.log('停止轮询')
      if (pollingInterval.value) {
        clearInterval(pollingInterval.value)
        pollingInterval.value = null
      }
    }

    const downloadImage = async (url) => {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = `generated-image-${Date.now()}.jpg`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(downloadUrl)
      } catch (error) {
        errorMessage.value = '下载图片失败'
        setTimeout(() => errorMessage.value = '', 3000)
      }
    }

    const previewImage = (url) => {
      previewUrl.value = url
    }

    const handleFileUpload = async (event) => {
      const file = event.target.files[0]
      if (!file) return

      const formData = new FormData()
      formData.append('file', file)

      try {
        isLoading.value = true
        const response = await axios.post('https://chat.chatgptten.com/screenshots/img/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response.data.success) {
          uploadedImageUrl.value = response.data.data.url
          // 将图片URL添加到prompt开头
          prompt.value = `${response.data.data.url} ${prompt.value}`
        } else {
          throw new Error('上传失败')
        }
      } catch (error) {
        console.error('上传图片失败:', error)
        errorMessage.value = '上传图片失败'
        setTimeout(() => errorMessage.value = '', 3000)
      } finally {
        isLoading.value = false
      }
    }

    const removeUploadedImage = () => {
      uploadedImageUrl.value = null
      // 从prompt中移除图片URL
      prompt.value = prompt.value.replace(uploadedImageUrl.value, '').trim()
      if (fileInput.value) {
        fileInput.value.value = ''
      }
    }

    return {
      prompt,
      isLoading,
      errorMessage,
      generatedImages,
      generateImages,
      downloadImage,
      previewUrl,
      previewImage,
      selectedSize,
      imageSizes,
      uploadedImageUrl,
      fileInput,
      handleFileUpload,
      removeUploadedImage,
    }
  }
}
</script>