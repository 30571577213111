<template>
  <div>
    <header>
      <div class="container">
        <h1>AI技术革新 - 引领未来</h1>
        <p class="intro">探索AI的无限可能，为您的企业和个人发展注入智能动力</p>
      </div>
    </header>
    <main class="container">
      <section class="services">
        <div v-for="service in services" :key="service.title" class="service">
          <h2>{{ service.title }}</h2>
          <p>{{ service.description }}</p>
        </div>
      </section>
      <section class="cta">
        <h2>准备好迎接AI带来的革新了吗？</h2>
        <p>联系我们，开启您的AI之旅！</p>
        <a href="#" class="cta-button" @click="goToQcode">立即咨询</a>
      </section>
    </main>

  </div>
</template>

<script>
export default {
  name: 'AITechInnovation',
  data() {
    return {
      services: [
        { title: 'AI代写', description: '利用先进的自然语言处理技术，为您生成高质量的文本内容，提高写作效率。' },
        { title: 'AI智能体', description: '打造智能助手和虚拟代理，为您的业务提供24/7全天候服务支持。' },
        { title: 'AI企业培训', description: '定制化AI培训方案，助力企业员工快速掌握AI技能，提升工作效率。' },
        { title: 'AI出海', description: '借助AI技术，助力企业跨境扩张，精准定位海外市场，实现全球化战略。' },
        { title: 'AIP打造', description: '构建强大的AI平台（AIP），为您的企业提供一站式AI解决方案。' },
        { title: 'AI直播', description: '革新直播体验，利用AI技术提升互动质量，吸引更多观众。' },
        { title: 'RPA商业', description: '通过机器人流程自动化（RPA），优化业务流程，提高运营效率。' },
        { title: '个人IP新流量玩法', description: '运用AI技术，打造独特的个人品牌，开拓创新的流量获取渠道。' },
      ],
      showPopup: false
    }
  },
  methods: {
    goToQcode() {
      this.$router.push('/qcode');
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    hidePopup() {
      this.showPopup = false;
    }
  }
}
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
header {
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  text-align: center;
  padding: 50px 0;
}
h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}
.intro {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
}
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}
.service {
  flex-basis: calc(33.333% - 20px);
  margin-bottom: 40px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}
.service:hover {
  transform: translateY(-5px);
}
.service h2 {
  color: #6e8efb;
}
.cta {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background: #f0f0f0;
  border-radius: 8px;
}
.cta-button {
  display: inline-block;
  padding: 12px 24px;
  background: #6e8efb;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s ease;
}
.cta-button:hover {
  background: #5c7cfa;
}
@media (max-width: 768px) {
  .service {
    flex-basis: 100%;
  }
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
.popup img {
  max-width: 100%;
  height: auto;
}
.popup button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background: #6e8efb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>