<template>
  <!-- 添加错误提示 -->
  <div v-if="errorMessage" class="fixed top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg z-50">
    {{ errorMessage }}
  </div>
  
  <div class="bg-gradient-to-b from-gray-900 to-black min-h-screen py-8">
    <div class="container mx-auto px-4 max-w-5xl">
      <!-- 标题区域 -->
      <div class="text-center mb-12">
        <h1 class="text-4xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
          AI虚拟试衣间
        </h1>
        <p class="text-gray-400 mt-2">上传照片，即刻体验智能换装</p>
      </div>

      <!-- 主要内容区域 -->
      <div class="bg-gray-800 rounded-2xl p-8 shadow-xl">
        <!-- 上传区域 -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <!-- 人物照片上传 -->
          <div class="space-y-4">
            <h3 class="text-xl font-medium text-white mb-4">上传人物照片</h3>
            <div 
              class="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center hover:border-purple-500 transition-colors"
              @drop.prevent="handleHumanImageDrop"
              @dragover.prevent
            >
              <div v-if="!humanImagePreview" class="space-y-4">
                <i class="fas fa-user-circle text-4xl text-gray-500"></i>
                <p class="text-gray-400">拖拽或点击上传人物照片</p>
                <input 
                  type="file"
                  @change="handleHumanImageUpload"
                  class="hidden"
                  ref="humanImageInput"
                  accept="image/*"
                />
                <button 
                  @click="$refs.humanImageInput.click()"
                  class="px-4 py-2 bg-purple-600 rounded-lg text-white hover:bg-purple-700"
                >
                  选择图片
                </button>
              </div>
              <img 
                v-else 
                :src="humanImagePreview" 
                class="max-h-64 mx-auto rounded-lg"
                alt="人物预览"
              />
              <p v-if="humanUploadSuccess" class="mt-2 text-sm text-green-600">图片上传成功!</p>
            </div>
          </div>

          <!-- 服装照片上传 -->
          <div class="space-y-4">
            <h3 class="text-xl font-medium text-white mb-4">上传服装照片</h3>
            <div 
              class="border-2 border-dashed border-gray-600 rounded-lg p-6 text-center hover:border-pink-500 transition-colors"
              @drop.prevent="handleClothImageDrop"
              @dragover.prevent
            >
              <div v-if="!clothImagePreview" class="space-y-4">
                <i class="fas fa-tshirt text-4xl text-gray-500"></i>
                <p class="text-gray-400">拖拽或点击上传服装照片</p>
                <input 
                  type="file"
                  @change="handleClothImageUpload"
                  class="hidden"
                  ref="clothImageInput"
                  accept="image/*"
                />
                <button 
                  @click="$refs.clothImageInput.click()"
                  class="px-4 py-2 bg-pink-600 rounded-lg text-white hover:bg-pink-700"
                >
                  选择图片
                </button>
              </div>
              <img 
                v-else 
                :src="clothImagePreview" 
                class="max-h-64 mx-auto rounded-lg"
                alt="服装预览"
              />
              <p v-if="clothUploadSuccess" class="mt-2 text-sm text-green-600">图片上传成功!</p>
            </div>
          </div>
        </div>

        <!-- 生成按钮 -->
        <div class="mt-8 text-center">
          <button
            @click="generateTryOn"
            class="px-8 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg text-white font-medium hover:from-purple-700 hover:to-pink-700 disabled:opacity-50"
            :disabled="!canGenerate || isLoading"
          >
            <span v-if="!isLoading">开始生成</span>
            <span v-else class="flex items-center justify-center">
              <i class="fas fa-spinner fa-spin mr-2"></i>
              生成中...
            </span>
          </button>
        </div>

        <!-- 结果展示 -->
        <div v-if="resultImage" class="mt-8">
          <h3 class="text-xl font-medium text-white mb-4">生成结果</h3>
          <div class="bg-gray-900 rounded-lg p-4">
            <img 
              :src="resultImage" 
              class="max-w-full mx-auto rounded-lg"
              alt="生成结果"
            />
            <div class="mt-4 flex justify-center">
              <button
                @click="downloadResult"
                class="px-4 py-2 bg-green-600 rounded-lg text-white hover:bg-green-700"
              >
                <i class="fas fa-download mr-2"></i>
                下载结果
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 加载遮罩 -->
    <div v-if="isLoading" class="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div class="bg-white rounded-lg p-8 max-w-md w-full mx-4">
        <div class="text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-4 border-purple-500 border-t-transparent mx-auto"></div>
          <p class="mt-4 text-gray-800">{{ loadingMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import axios from 'axios'

const humanImageInput = ref(null)
const clothImageInput = ref(null)
const humanImagePreview = ref(null)
const clothImagePreview = ref(null)
const isLoading = ref(false)
const loadingMessage = ref('')
const resultImage = ref(null)
const errorMessage = ref('')
const humanUploadSuccess = ref(false)
const clothUploadSuccess = ref(false)
const humanImageUrl = ref(null)
const clothImageUrl = ref(null)

// 判断是否可以生成
const canGenerate = computed(() => {
  return humanImagePreview.value && clothImagePreview.value
})

// 处理人物图片上传
const handleHumanImageUpload = async (event) => {
  const file = event.target.files[0]
  if (file && file.type.startsWith('image/')) {
    humanImagePreview.value = URL.createObjectURL(file)
    // 上传图片到服务器
    try {
      const imageUrl = await uploadImage(file)
      humanImageUrl.value = imageUrl
      humanUploadSuccess.value = true
      setTimeout(() => {
        humanUploadSuccess.value = false
      }, 3000)
    } catch (error) {
      console.error('上传人物图片失败:', error)
      errorMessage.value = '上传图片失败'
    }
  }
}

// 处理服装图片上传
const handleClothImageUpload = async (event) => {
  const file = event.target.files[0]
  if (file && file.type.startsWith('image/')) {
    clothImagePreview.value = URL.createObjectURL(file)
    // 上传图片到服务器
    try {
      const imageUrl = await uploadImage(file)
      clothImageUrl.value = imageUrl
      clothUploadSuccess.value = true
      setTimeout(() => {
        clothUploadSuccess.value = false
      }, 3000)
    } catch (error) {
      console.error('上传服装图片失败:', error)
      errorMessage.value = '上传图片失败'
    }
  }
}

// 处理拖拽上传
const handleHumanImageDrop = async (event) => {
  const file = event.dataTransfer.files[0]
  if (file && file.type.startsWith('image/')) {
    await handleHumanImageUpload({ target: { files: [file] } })
  }
}

const handleClothImageDrop = async (event) => {
  const file = event.dataTransfer.files[0]
  if (file && file.type.startsWith('image/')) {
    await handleClothImageUpload({ target: { files: [file] } })
  }
}

// 上传图片到服务器
const uploadImage = async (file) => {
  const formData = new FormData()
  formData.append('file', file)
  try {
    const response = await axios.post('https://chat.chatgptten.com/screenshots/img/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data && response.data.success && response.data.data.url) {
      return response.data.data.url
    } else {
      throw new Error('Invalid response from image upload: ' + response.data.msg)
    }
  } catch (error) {
    console.error('Error uploading image:', error)
    throw error
  }
}

// 生成虚拟试衣结果
const generateTryOn = async () => {
  console.log('[generateTryOn] 开始生成虚拟试衣')
  try {
    if (!humanImageUrl.value || !clothImageUrl.value) {
      console.warn('[generateTryOn] 图片URL未准备好:', {
        humanImageUrl: humanImageUrl.value,
        clothImageUrl: clothImageUrl.value
      })
      throw new Error('请先上传图片')
    }

    errorMessage.value = ''
    isLoading.value = true
    loadingMessage.value = '正在生成试衣效果...'
    
    // 构建请求体
    const requestBody = {
      model: 'kolors-virtual-try-on',
      human_image: humanImageUrl.value,
      cloth_image: clothImageUrl.value,
      seed: Math.floor(Math.random() * 1000000)
    }
    console.log('[generateTryOn] 请求体:', requestBody)
    
    console.log('[generateTryOn] 发送请求到换衣接口...')
    const response = await axios.post('/proxy/api/v1/images/virtual-try-on', requestBody)
    console.log('[generateTryOn] 接口返回数据:', response.data)
    
    if (response.data.data && response.data.data.length > 0) {
      resultImage.value = response.data.data[0].url
      console.log('[generateTryOn] 生成成功，结果URL:', resultImage.value)
    } else {
      console.error('[generateTryOn] 返回数据格式错误:', response.data)
      throw new Error('生成结果格式错误')
    }

  } catch (error) {
    console.error('[generateTryOn] 生成失败:', error)
    console.error('[generateTryOn] 错误详情:', {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      responseData: error.response?.data
    })
    errorMessage.value = error.message || '生成失败，请重试'
    setTimeout(() => {
      errorMessage.value = ''
    }, 3000)
  } finally {
    isLoading.value = false
    console.log('[generateTryOn] 生成流程结束')
  }
}

// 下载结果
const downloadResult = async () => {
  console.log('[downloadResult] 开始下载结果')
  if (resultImage.value) {
    try {
      console.log('[downloadResult] 下载URL:', resultImage.value)
      // 使用 fetch 获取图片数据
      const response = await fetch(resultImage.value)
      const blob = await response.blob()
      
      // 创建下载链接
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'virtual-tryon-result.png' // 设置下载文件名
      document.body.appendChild(link)
      link.click()
      
      // 清理
      URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
      
      console.log('[downloadResult] 下载完成')
    } catch (error) {
      console.error('[downloadResult] 下载失败:', error)
      errorMessage.value = '下载失败，请重试'
    }
  } else {
    console.warn('[downloadResult] 没有可下载的结果')
  }
}
</script>

<style scoped>
.bg-gradient-to-b {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>