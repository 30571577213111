<template>
  <div class="centered-content">
    <div class="centered-content-inner">
      <h1 class="title">请扫码联系客服同学</h1>
      <div class="qr-container">
        <img src="/images/weixin.png" alt="二维码活动码">
      </div>
      <p class="subtitle">扫一扫即可添加客服微信</p>
    </div>
  </div>
</template>

<style scoped>
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.centered-content-inner {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.centered-content-inner:hover {
  transform: translateY(-5px);
}

.title {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 2rem;
  font-weight: 600;
}

.qr-container {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin: 1rem 0;
}

.centered-content img {
  width: 220px;
  height: 220px;
  display: block;
}

.subtitle {
  color: #666;
  font-size: 16px;
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .centered-content-inner {
    padding: 2rem;
    margin: 1rem;
  }
  
  .title {
    font-size: 24px;
  }
  
  .centered-content img {
    width: 180px;
    height: 180px;
  }
}
</style>
