<template>
  <div class="flex flex-col h-screen bg-black text-white" @click.self="handleOutsideClick">
    <!-- 顶部导航栏 -->
    <nav class="bg-black shadow-md">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0 flex items-center">
              <!-- Netflix风格的logo -->
              <img 
                class="h-8 w-auto cursor-pointer" 
                src="https://gw.chatgptten.com/icons/icon-192x192.png" 
                alt="智言AI Logo"
                @click.stop="router.push('/setting')"
              >
            </div>
            <!-- 桌面导航 -->
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <!-- 视频生成器分类 -->
              <div class="relative group">
                <button class="text-gray-300 hover:text-white px-1 pt-1 text-sm font-medium">
                  视频生成器
                  <span class="ml-1">▼</span>
                </button>
                <div class="absolute hidden group-hover:block w-48 bg-gray-800 rounded-md shadow-lg z-50">
                  <a v-for="item in videoTools"
                     :key="item.text"
                     @click.stop="item.action()"
                     class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 cursor-pointer">
                    {{ item.text }}
                  </a>
                </div>
              </div>

              <!-- 图像工具分类 -->
              <div class="relative group">
                <button class="text-gray-300 hover:text-white px-1 pt-1 text-sm font-medium">
                  图像工具
                  <span class="ml-1">▼</span>
                </button>
                <div class="absolute hidden group-hover:block w-48 bg-gray-800 rounded-md shadow-lg z-50">
                  <a v-for="item in imageTools"
                     :key="item.text"
                     @click.stop="item.action()"
                     class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 cursor-pointer">
                    {{ item.text }}
                  </a>
                </div>
              </div>

              <!-- 新增智能问答分类 -->
              <div class="relative group">
                <button class="text-gray-300 hover:text-white px-1 pt-1 text-sm font-medium">
                  智能问答
                  <span class="ml-1">▼</span>
                </button>
                <div class="absolute hidden group-hover:block w-48 bg-gray-800 rounded-md shadow-lg z-50">
                  <a v-for="item in aiChatTools"
                     :key="item.text"
                     @click.stop="item.action()"
                     class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 cursor-pointer">
                    {{ item.text }}
                  </a>
                </div>
              </div>

              <!-- 其他重要功能直接显示 -->
              <a v-for="button in mainButtons"
                 :key="button.text"
                 class="text-gray-300 hover:text-white px-1 pt-1 text-sm font-medium"
                 @click="handleNavClick(button)">
                {{ button.text }}
              </a>
            </div>
          </div>
          <!-- 用户菜单 -->
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <div class="ml-3 relative">
              <div>
                <button 
                  type="button" 
                  @click.stop="router.push('/setting')"
                  class="bg-gray-800 rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" 
                  id="user-menu-button" 
                  aria-expanded="false" 
                  aria-haspopup="true">
                  <span class="sr-only">Open user menu</span>
                  <img 
                    class="h-8 w-8 rounded-full" 
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" 
                    alt="">
                </button>
              </div>
            </div>
          </div>
          <!-- 加汉堡菜单按钮 -->
          <div class="sm:hidden flex items-center">
            <button @click="toggleMobileMenu" class="text-gray-300 hover:text-white">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- 移动端导航菜单 -->
      <div v-show="isMobileMenuOpen" class="sm:hidden">
        <!-- 分类显示 -->
        <div class="space-y-1">
          <div class="px-3 py-2 text-gray-400 text-sm">视频工具</div>
          <a v-for="item in videoTools"
             :key="item.text"
             @click="handleMobileMenuItemClick(item)"
             class="block px-4 py-2 text-gray-300">
            {{ item.text }}
          </a>

          <div class="px-3 py-2 text-gray-400 text-sm">图像工具</div>
          <a v-for="item in imageTools"
             :key="item.text"
             @click="handleMobileMenuItemClick(item)"
             class="block px-4 py-2 text-gray-300">
            {{ item.text }}
          </a>

          <div class="px-3 py-2 text-gray-400 text-sm">智能问答</div>
          <a v-for="item in aiChatTools"
             :key="item.text"
             @click="handleMobileMenuItemClick(item)"
             class="block px-4 py-2 text-gray-300">
            {{ item.text }}
          </a>

          <div class="border-t border-gray-700 my-2"></div>

          <a v-for="button in mainButtons"
             :key="button.text"
             @click="handleMobileMenuItemClick(button)"
             class="block px-3 py-2 text-gray-300">
            {{ button.text }}
          </a>
        </div>
      </div>
    </nav>

    <!-- 主要内容区 -->
    <main class="flex-grow overflow-hidden">
      <iframe
          :src="currentIframeSrc"
          frameborder="0"
          class="w-full h-full"
      ></iframe>
    </main>

    <!-- 客服弹窗 -->
    <div v-if="showQcodeModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div class="bg-gray-900 rounded-lg shadow-lg p-6">
        <img :src="qcodeSrc" alt="客服二维码" class="mx-auto w-48">
        <button class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4" @click="showQcodeModal = false">
          关闭
        </button>
      </div>
    </div>

    <!-- 页脚 -->
    <footer class="bg-black">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div class="flex flex-wrap justify-center space-x-6">
          <a href="#" @click.prevent="goToAbout" class="text-gray-400 hover:text-white">关于我们</a>
          <a href="#" @click.prevent="goToNews" class="text-gray-400 hover:text-white">最新资讯</a>
          <a href="#" @click.prevent="tutorial" class="text-gray-400 hover:text-white">AI 教程</a>
          <a href="#" @click.prevent="openPlatform" class="text-gray-400 hover:text-white">开放平台</a>
          <span class="text-gray-400">© 2024 智言AI. 版权所有.</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter();
    const route = useRoute();

    // 修改默认 iframe 地址为 AI 音乐绘画的地址
    const currentIframeSrc = ref('https://study.chatgptten.com/');
    const showQcodeModal = ref(false);
    const qcodeSrc = ref('https://chat.chatgptten.com/images/weixin.png');
    const hasTag = ref(false);
    const invitationCode = ref('');
    const userLoggedIn = ref(false);
    const isMobileMenuOpen = ref(false);
    const pValue = ref(null);
    const isUserMenuOpen = ref(false);

    const allButtons = ref([
      { text: 'Luma ', action: () => updateIframe('https://chat.chatgptten.com/video-generator2'), isActive: false },
      { text: 'Runway ', action: () => updateIframe('https://mall.chatgptten.com/video-generator'), isActive: false },
      { text: 'Kling可灵 ', action: () => updateIframe('https://study.chatgptten.com/ '), isActive: false },
      { text: 'Pixverse ', action: () => updateIframe('https://chat.chatgptten.com/pixverse'), isActive: false },
      { text: 'Pika ', action: () => updateIframe('https://chat.chatgptten.com/pika'), isActive: false },
      { text: '海螺AI ', action: () => updateIframe('https://mall.chatgptten.com/video-generator-hailuo'), isActive: false },
      { text: 'AI艺术', action: () => updateIframe('https://study.chatgptten.com/'), isActive: true },
      { text: '音乐广场', action: () => router.push('/musicplayer'), isActive: false },
      { text: '作品', action: () => updateIframe('/image-gallery'), isActive: false },
      { text: '价格', action: () => updateIframe('https://mall.chatgptten.com/'), isActive: false },
      { text: '客服', action: showQcode, isActive: false },
    ]);

    const visibleButtons = computed(() => {
      if (pValue.value === '1' || localStorage.getItem('pValue') === '1') {
        return allButtons.value.filter(button =>
            button.text !== '联系客服' && button.text !== '套餐充值'
        );
      }
      return allButtons.value;
    });

    onMounted(() => {
      initializeComponent();
      handleQueryParams();
    });

    function initializeComponent() {
      handlePValue();
      handleInvitationCode();
      fetchAndUpdateLocalStorage();
    }

    function handlePValue() {
      const urlParams = new URLSearchParams(window.location.search);
      const p = urlParams.get('p');
      if (p) {
        pValue.value = p;
        localStorage.setItem('pValue', p);
      } else {
        pValue.value = localStorage.getItem('pValue');
      }
    }

    function handleInvitationCode() {
      const removeInvitationCode = route.query.removeInvitationCode;
      if (removeInvitationCode === 'delete') {
        localStorage.removeItem('invitationCode');
        console.log('invitationCode removed. Skipping further execution.');
        return;
      }

      const storedInvitationCode = localStorage.getItem('invitationCode');
      if (storedInvitationCode) {
        invitationCode.value = storedInvitationCode;
      } else {
        invitationCode.value = route.query.invitationCode;
        if (invitationCode.value) {
          localStorage.setItem('invitationCode', invitationCode.value);
        }
      }
      console.log('The invitation code is:', invitationCode.value);
    }

    function handleQueryParams() {
      const { token, uid, expiration, tag, s } = route.query;

      if (token) localStorage.setItem("token", token);
      if (uid) localStorage.setItem("uid", uid);
      if (expiration) localStorage.setItem("expiration", expiration);
      if (tag) localStorage.setItem("tag", tag);

      console.log('Token:', token, 'UID:', uid, 'Expiration:', expiration, 'Tag:', tag);

      if (s) {
        const targetIndex = allButtons.value.findIndex(button => button.text === '价格');
        if (targetIndex !== -1) {
          setActiveButton(targetIndex);
          allButtons.value[targetIndex].action();
        }
      }
    }

    function fetchAndUpdateLocalStorage() {
      updateButtonsBasedOnAuth();

      const url = `https://chat.chatgptten.com/u/global_users/user/me`;

      fetch(url)
          .then(response => response.json())
          .then(data => {
            userLoggedIn.value = data.success;

            if (data && data.data) {
              const { expirationTime, tag, id } = data.data;  // 添加 id 的解构
              const expirationTimestamp = new Date(expirationTime).getTime();
              console.log('Fetched expirationTime:', expirationTime);
              console.log('Fetched expirationTimestamp:', expirationTimestamp);
              console.log('Fetched tag:', tag);
              console.log('Fetched id:', id);  // 添加日志输出

              if (expirationTime) localStorage.setItem("expiration", expirationTimestamp.toString());
              if (tag) localStorage.setItem("tag", tag);
              if (id) localStorage.setItem("uid", id.toString());  // 添加 id 的保存
            }

            updateButtonsBasedOnAuth();
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            updateButtonsBasedOnAuth();
          });
    }

    function updateButtonsBasedOnAuth() {
      const tag = localStorage.getItem('tag');
      hasTag.value = !!tag;
      console.log('Current tag value:', tag);

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      allButtons.value = allButtons.value.filter(button => button.text !== '我的资料' && button.text !== '登录/注册');

      if (hasTag.value || token) {
        allButtons.value.push({ text: '我的资料', action: setting, isActive: false });
      } else {
        allButtons.value.push({ text: '登录/注册', action: login, isActive: false });
      }

      console.log('Buttons updated:', allButtons.value);
    }

    function updateIframe(newSrc) {
      currentIframeSrc.value = newSrc;
    }

    function showQcode() {
      showQcodeModal.value = true;
    }

    function setting() {
      router.push('/setting');
    }

    function login() {
      window.open('https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/', '_blank');
    }

    function goToAbout() {
      router.push('/about');
    }

    function goToNews() {
      window.open('https://news.chatgptten.com/', '_blank');
    }

    function tutorial() {
      window.open('https://gqoy5zk2d1i.feishu.cn/wiki/VS0owZsV6iGxCNk5svQcE7cAnsb', '_blank');
    }

    function toggleMobileMenu() {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    }

    function handleOutsideClick(event) {
      if (isMobileMenuOpen.value && !event.target.closest('.sm\\:hidden')) {
        isMobileMenuOpen.value = false;
      }
    }

    function handleMobileMenuItemClick(button) {
      button.action();
      isMobileMenuOpen.value = false;
    }

    function handleNavClick(button) {
      allButtons.value.forEach(btn => btn.isActive = false);
      button.isActive = true;
      button.action();
    }

    watch(allButtons, (newButtons) => {
      console.log('Buttons changed:', newButtons);
    }, { deep: true });

    const setActiveButton = (index) => {
      allButtons.value.forEach((button, i) => {
        button.isActive = i === index;
      });
    };

    const openPlatform = () => {
      if (localStorage.getItem('token')) {
        updateIframe('https://mp.chatgptten.com/dashboard');
      } else {
        alert('您尚未登录，请先登录');
      }
    };

    // 重新组视频工具分类
    const videoTools = [
      {
        text: 'Luma',
        action: () => {
          console.log('Clicking Luma');
          updateIframe('https://chat.chatgptten.com/video-generator2');
        }
      },
      {
        text: 'Runway',
        action: () => {
          console.log('Clicking Runway');
          updateIframe('https://mall.chatgptten.com/video-generator');
        }
      },
      {
        text: '海螺AI',
        action: () => {
          console.log('Clicking 海螺AI');
          updateIframe('https://mall.chatgptten.com/video-generator-hailuo');
        }
      },
      {
        text: '可灵AI',
        action: () => {
          console.log('Clicking Kling可灵');
          updateIframe('https://mall.chatgptten.com/imagine-creator');
        }
      },
      {
        text: 'Pixverse',
        action: () => {
          console.log('Clicking Pixverse');
          updateIframe('https://chat.chatgptten.com/pixverse');
        }
      },
      {
        text: 'Pika',
        action: () => {
          console.log('Clicking Pika');
          updateIframe('https://chat.chatgptten.com/pika');
        }
      },
      {
        text: '腾讯混元AI',
        action: () => {
          console.log('Clicking 腾讯混元AI');
          updateIframe('https://mall.chatgptten.com/video-generator-hunyuan');
        }
      },
    ];

    // 更新图像工具分类
    const imageTools = [
      {
        text: 'MidJourney',
        action: () => {
          console.log('Clicking MidJourney');
          updateIframe('https://study.chatgptten.com/ ');
        }
      },
      {
        text: 'Dall.E',
        action: () => {
          console.log('Clicking Dall.E');
          updateIframe('https://study.chatgptten.com/ ');
        }
      },
      {
        text: 'IdeoGram',
        action: () => {
          console.log('Clicking IdeoGram');
          updateIframe('https://study.chatgptten.com/ ');
        }
      },
      {
        text: 'Flux',
        action: () => {
          console.log('Clicking Flux');
          updateIframe('https://study.chatgptten.com/');
        }
      },
      // 新增即梦AI选项
      {
        text: '即梦AI',
        action: () => {
          console.log('Clicking 即梦AI');
          updateIframe('https://chat.chatgptten.com/provider-generator/video-generator/jimeng-generator');
        }
      },
      // 新增虚拟换衣选项
      {
        text: '虚拟换衣AI',
        action: () => {
          console.log('Clicking 虚拟换衣');
          updateIframe('https://chat.chatgptten.com/provider-generator/video-generator/virtualtryon-generator');
        }
      },
    ];

    // 新增智问答工具分类
    const aiChatTools = [
      {
        text: 'GPT 4.0模型',
        action: () => {
          console.log('Clicking GPT 4.0');
          updateIframe('https://study.chatgptten.com/');
        }
      },
      {
        text: 'Claude 3.5模型',
        action: () => {
          console.log('Clicking Claude 3.5');
          updateIframe('https://study.chatgptten.com/');
        }
      },
    ];

    // 更新主按钮
    const mainButtons = [
      {
        text: '图片资源',
        action: () => updateIframe('/image-gallery')
      },
      {
        text: '音乐广场',
        action: () => router.push('/musicplayer')
      },
      {
        text: '价格',
        action: () => updateIframe('https://mall.chatgptten.com/')
      },
      {
        text: '客服',
        action: showQcode
      },
    ];

    function toggleUserMenu() {
      isUserMenuOpen.value = !isUserMenuOpen.value;
    }

    return {
      router,
      currentIframeSrc,
      showQcodeModal,
      qcodeSrc,
      visibleButtons,
      isMobileMenuOpen,
      updateIframe,
      showQcode,
      goToAbout,
      goToNews,
      tutorial,
      setActiveButton,
      toggleMobileMenu,
      handleOutsideClick,
      handleMobileMenuItemClick,
      handleNavClick,
      openPlatform,
      videoTools,
      imageTools,
      aiChatTools,
      mainButtons,
      isUserMenuOpen,
      toggleUserMenu,
    };
  },
};
</script>

<style scoped>
body {
  background-color: #141414;
  color: #ffffff;
}

.bg-red-600 {
  background-color: #e50914;
}

.hover\:bg-red-700:hover {
  background-color: #b20710;
}

.focus\:ring-red-500:focus {
  --tw-ring-color: #e50914;
}

/* 添加一些样式确保下拉菜单正常显示 */
.group:hover .hidden {
  display: block;
}

/* 确保下拉菜单在其他元素之上 */
.z-50 {
  z-index: 50;
}

/* 添加鼠标手型 */
.cursor-pointer {
  cursor: pointer;
}

/* 添加渐变背景 */
.nav-bg {
  background: linear-gradient(to bottom, #000000, #1a1a1a);
}

/* 增加过渡动画 */
.transition-all {
  transition: all 0.3s ease;
}

/* 优化间距 */
.content-wrapper {
  padding: 2rem 4rem;
  max-width: 1440px;
  margin: 0 auto;
}

/* 添加阴影效果 */
.card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>